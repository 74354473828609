<template>
  <div class="stat¡c-progress py-5">
    <div class="mr-5">{{ value }}%</div>
    <v-progress-linear
      :value="value"
      :height="10"
      background-color="tway-gray"
      style="border-radius: 9px !important"
      color="#16C62E"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped>
.stat¡c-progress {
  background-color: #333333;
  color: #fff;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 5px);
  left: 0px;
  bottom: 0;
  padding-left: 16.6666666667%;
  padding-right: 16.6666666667%;
}

@media (max-width: 1264px) {
  .stat¡c-progress {
    width: 100%;
    left: 0px;
  }
}
</style>
