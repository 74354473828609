<template>
  <div>
    <breadcrumbs :items="breadcrumbs" />
    <Loader :visible="loading" />
    <v-row v-if="!loading">
      <v-col md="8" offset-md="2">
        <div class="breadcrumb">
          <span style="cursor: pointer" @click="$router.push({ name: 'home' })">Inicio</span>
          <span class="ml-4">></span>
          <span class="ml-4" style="color: #999999">Encuesta Índice de Madurez Organizacional</span>
        </div>
        <div class="title mt-5">Encuesta Índice de Madurez Organizacional</div>
        <div class="subtitle">
          Responde estas preguntas para identificar el estado de madurez de tu organización y descubrir qué tan
          preparada está para enfrentar los procesos de innovación e integración de tecnología en la economía actual.
        </div>
        <div class="subtitle-bold">
          Indica tu calificación sobre las actividades que se hacen o no en la empresa, considerando una escala de 1 a
          5, donde 1 es la nota más baja y 5 la más alta.
        </div>
        <answers-list class="mt-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AnswersList from "@/components/survey/AnswersList";
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
export default {
  components: {
    Loader,
    Breadcrumbs,

    AnswersList,
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      loading: true,
      breadcrumbs: [
        {
          text: "Inicio",
          disabled: false,
          exact: true,
          to: {
            name: "home",
          },
        },
        {
          text: "Encuesta IMO",
          disabled: true,
          exact: true,
          to: {
            name: "survey-imo",
          },
        },
      ],
    };
  },
  async created() {
    await this.surveyComplete(this.userId);
    if (this.percentageImo == 100) {
      this.$router.push({ name: "dashboard-imo" });
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("DigitalTransformationStore", ["surveyComplete"]),
  },

  computed: {
    ...mapGetters("DigitalTransformationStore", ["percentageImo"]),
  },
};
</script>
<style lang="css" scoped>
.breadcrumb {
  text-align: center;
  font-size: 16px;
  font-family: Roboto Condensed;
  color: #7319d5;
}
.title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 150%;
  color: #333333;
  text-align: center;
}
.subtitle {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: center;
  margin-top: 10px;
}
.subtitle-bold {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  margin-top: 35px;
}
</style>
