<template>
  <div>
    <div v-for="counter in questions.length" :key="counter">
      <div v-if="counter - 1 == page">
        <div class="pa-5 question-container" v-for="(question, qIndex) in questions[counter - 1]" :key="qIndex">
          <div class="question-label">
            <span class="roboto question-text pr-8 pl-2 text-align-center">
              {{ 5 * (counter - 1) + (qIndex + 1) }}.
              {{ question.title }}
            </span>
          </div>
          <v-row class="mb-0 mt-2 pb-4 d-none d-md-flex">
            <v-col cols="12" md="12" class="pa-0 ma-0" align-self="center">
              <div class="survey-labels ml-4 mr-4">
                <span class="roboto survey-intructions">Totalmente en desacuerdo</span>
                <span class="roboto survey-intructions">Totalmente de acuerdo</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-1 d-none d-md-flex">
            <v-col class="py-0">
              <answer :labels="true" :answers="[1, 2, 3, 4, 5]" :min="1" :max="5" />
            </v-col>
          </v-row>
          <v-row class="mt-1 mb-4">
            <v-col class="py-0">
              <answer
                @response="handleResponse(question.id, $event.toString())"
                :answers="[1, 2, 3, 4, 5]"
                :min="1"
                :max="5"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <div class="mb-12 mt-12 mt-sm-0 d-flex flex-column-reverse flex-sm-row align-center justify-space-between">
      <v-btn
        depressed
        rounded
        color="transparent"
        class="button-text tway-violet--text mt-4 mt-sm-11 mb-10"
        :to="{ name: 'home' }"
      >
        volver al inicio
      </v-btn>
      <v-btn
        style="height: 36px"
        rounded
        :disabled="levelAnswer"
        color="tway-violet"
        class="button-text elevation-8 white--text"
        @click="nextPage()"
      >
        <span style="height: 14px" v-if="page != questions.length - 1">Guardar y continuar</span>
        <span style="height: 14px" v-else>ver resultado</span>
      </v-btn>
    </div>
    <progress-bar :value="progress" />
  </div>
</template>

<script>
import SurveyAnswer from "@/components/elements/SurveyAnswer";
import StaticProgressBar from "@/components/elements/StaticProgressBar";
import currentUserService from "@/services/currentUserService";
import CompanyService from "@/services/companyService";
import DtService from "@/services/dtService";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    answer: SurveyAnswer,
    progressBar: StaticProgressBar,
  },
  data() {
    return {
      progress: 0,
      loading: false,
      dialog: false,
      page: 0,
      answers: {},
      response: null,
      current: 0,
      clientType: "",
      client: {
        activity: {},
        address: null,
        checked: null,
        contributionValue: "",
        countryCode: "",
        dimensions: [],
        enabled: null,
        firstLogin: null,
        id: "",
        industrialSectors: [],
        lastUpdate: "",
        linkedIn: "",
        name: "",
        services: [],
        size: {},
        trajectory: null,
        tributaryId: "",
        type: "",
        web: "",
      },
      surveyAttrs: {},
      questions: [[]],
      levelAnswer: true,
      user: {
        accountNonExpired: null,
        accountNonLocked: null,
        applications: [],
        authorities: [],
        credentialsNonExpired: null,
        enabled: null,
        firstName: "",
        id: "",
        lastName: "",
        updatedOn: null,
        username: "",
      },
      lastQuestion: 0,
      data: {
        createdOn: null,
        description: null,
        enabled: null,
        expiredOn: null,
        goodbyeMessage: null,
        id: null,
        language: null,
        questions: [],
        title: null,
        welcomeMessage: null,
      },
    };
  },

  methods: {
    ...mapActions("ImoStore", ["setPercentage", "setAnswers"]),
    getCompany() {
      CompanyService.getCompanyProfile()
        .then((company) => {
          this.clientType = this._.get(company, "type");
          this.client = company;
        })
        .catch((err) => console.log(err));
    },
    getCurrentUserData() {
      currentUserService
        .currentUserData()
        .then((userData) => {
          this.user = userData;
          DtService.getSurveyComplete("imo-index-v1", this.user.id)
            .then((questions) => {
              this.setPercentage(Math.floor((questions.answered / questions.total) * 100));
              let temp = {};
              questions.answeredQuestions.forEach((q) => {
                temp[q] = "-";
              });
              this.setAnswers(temp);
              if (this.surveyProgress == 100) {
                this.$router.replace({ name: "home" });
              }
              this.getSurvey("imo-index-v1");
            })
            .catch((err) => {
              this.getSurvey("imo-index-v1");
              this.$log.error(err);
            });
        })
        .catch((err) => this.$log.error(err));
    },
    getSurvey() {
      this.isLoading = true;
      DtService.getSurvey("imo-index-v1")
        .then((questions) => {
          this.surveyAttrs = questions;
        })
        .catch((err) => this.$log.error(err))
        .then(() => (this.isLoading = false));
    },
    handleResponse(id, answer) {
      this.answers[id] = answer;
      let flag = false;
      this.questions[this.page].forEach((q) => {
        if (this.answers[q.id] == undefined) {
          flag = true;
        }
      });
      this.levelAnswer = flag;

      Object.size = function (obj) {
        var size = 0,
          key;
        for (key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            size++;
          }
        }
        return size;
      };

      this.progress = Math.floor((Object.size(this.answers) / this.data.questions.length) * 100);
    },
    nextPage() {
      let array = [];
      if (this.page < this.questions.length - 1) {
        // LLAMADA POR LAS RESPUESTAS
        this.questions[this.page].forEach((q) => {
          array.push({
            questionId: q.id,
            surveyId: this.data.id,
            answer: this.answers[q.id],
            userId: this.user.id,
            clientId: this.client.id,
          });
        });
        DtService.postAnswers(array)
          .then(() => {
            let container = document.getElementById("main-scroll");
            container.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            this.setPercentage(this.progress);
            this.setAnswers(this.answers);
          })
          .catch((err) => (/* eslint-disable */ oo_oo(), console.log("error", err, `24863c79_1`)));
        this.page++;
        this.levelAnswer = true;
        window.scrollTo(0, 0);
      } else {
        // LLAMADA POR LAS ULTIMAS RESPUESTAS Y REDIRIJIR
        this.questions[this.page].forEach((q) => {
          array.push({
            questionId: q.id,
            surveyId: this.data.id,
            answer: this.answers[q.id],
            userId: this.user.id,
            clientId: this.client.id
          });
        });
        DtService.postAnswers(array)
          .then(() => {
            this.setPercentage(this.progress);
            this.setAnswers(this.answers);
            this.$router.push({
              name: "dashboard-imo"
            });
          })

          .catch((err) => console.log("error", err));
      }
    }
  },
  watch: {
    dialog() {
      // Siempre la pagina anterior con la ultima pregunta es la que quedo guardada
      // y como la unica pagina que puede tener menos de 5 preguntas es la ultima, todas las demas siempre tendran 5
      // por lo que saber el numero de preguntas respondidas es multiplicar la cantidad de paginas menos uno por querer ver la pagina anterior (en este caso como las paginas empiezan desde cero no se resta)
      // en el caso de la primera pagina se guardará hasta la pregunta 0 -> 0 * 5 = 0 y corresponde
      this.lastQuestion = this.page * 5;
    },
    clientType() {},
    surveyAttrs() {
      this.data = this.surveyAttrs.content[0];
    },
    data() {
      //FIXME:
      const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
      let questions = this.data.questions;
      let index = 1;
      questions = questions.map((e) => {
        e.index = index;
        index++;
        return e;
      });
      function shuffle(array) {
        var currentIndex = array.length,
          temporaryValue,
          randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
      }
      if (this.getPercentage) {
        this.progress = this.getPercentage;
      } else {
        this.progress = 0;
      }
      let temp = JSON.parse(localStorage.getItem("answersImo"));
      if (temp && Object.values(temp).length != 0) {
        Object.keys(temp).forEach((key) => {
          this.answers[key] = temp[key];
        });
      } else {
        this.answers = {};
      }
      let answeredArray = [];
      let notAnsweredArray = [];
      questions.forEach((q) => {
        if (this.answers[q.id]) {
          answeredArray.push(q);
        } else {
          notAnsweredArray.push(q);
        }
      });
      notAnsweredArray = shuffle(notAnsweredArray);
      let finalArray = answeredArray;
      notAnsweredArray.forEach((q) => {
        finalArray.push(q);
      });
      this.questions = chunk(finalArray, 5);
      let flag = true;
      this.questions.forEach((array, i) => {
        array.forEach((q, questionIndex) => {
          if (flag && !this.answers[q.id]) {
            this.lastQuestion = i * 5 + questionIndex;
            this.page = i;
            flag = false;
            this.loading = true;
          }
        });
      });
    }
  },

  computed: {
    ...mapGetters("ImoStore", ["getPercentage"])
  },
  created() {
    this.getCompany();
    this.getCurrentUserData();
  },

  mounted() {
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.question-container {
  background-color: #f1f1f1;
}
.question-container:nth-of-type(2n) {
  background-color: #fff;
}

.v-input__control {
  width: 100% !important;
}
.survey-title {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  color: #333333;
}
.survey-progress {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.survey-intructions {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.v-label {
  width: 100px !important;
}
.question-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;

  /* Tway / Gris Oscuro */

  color: #333333;
}
.question-number {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.roboto {
  font-family: "Roboto Condensed", sans-serif !important;
}
.question-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.survey-labels {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  span {
    max-width: 120px;
    text-align: center;
  }
}
.text-align-left {
  width: 100%;
  text-align: left;
}
.v-banner {
  margin-top: -60px !important;
}
</style>
